<template>
  <div style="background:white;overflow:hidden">
    <el-dialog title="驳回"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="驳回理由:"
                          prop="teacher_comment">
              <el-input placeholder="请输入驳回理由"
                        type="textarea"
                        :rows="6"
                        v-model="form.teacher_comment"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="图片:">
              <el-row :gutter="10">
                <el-col :span="4"
                        style="position:relative"
                        v-for="(item,index) in srcList"
                        :key="item">
                  <el-image class="loadImage"
                            :src="item"
                            :preview-src-list="srcList">
                  </el-image>
                  <div class="delImage"
                       @click="delImage(index)">
                    <img src="@/assets/icons/close.png"
                         alt="">
                  </div>
                </el-col>
                <el-col :span="4"
                        v-if="srcList.length != 5">
                  <div class="add"
                       @click="uploadImage">
                    +
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { upload } from '@/api/upload.js'
import { feedbackSaveApi } from '@/api/feedback.js'
export default {
  data () {
    return {
      form: [],
      id: '',
      dialogVisible: false,
      showOpertion: true,
      srcList: [],
      rules: {
        teacher_comment: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
    }
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.showOpertion = true
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(async val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        if (this.srcList && this.srcList.length) {
          form.counter_image = this.srcList.join(',')
        }

        await feedbackSaveApi(form)
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.referenceParent()
      })
    },
    delImage (index) {
      this.srcList.splice(index, 1)
    },
    handleClose () {
      this.srcList = []
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    uploadImage () {
      this.$get_file({
        limit: 20,
        fileType: 'image/*',
      }).then(async res => {
        let formData = new FormData()
        formData.append('data', res)
        formData.append('filename', 'data')
        formData.append('sign', this.$getMd5Form({ filename: 'data' }))
        const { data } = await upload(formData)
        this.srcList.push(data.info.dir)
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.add {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #808080;
  cursor: pointer;
  border: 2px dashed #d9d9d9;
  border-radius: 5px;
  &:hover {
    border: 2px dashed #409eff;
    color: #409eff;
  }
}
.loadImage {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.delImage {
  cursor: pointer;
  position: absolute;
  color: white;
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 50%;
  top: -10px;
  right: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>