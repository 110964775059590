<template>
  <div style="background:white;overflow:hidden">
    <el-dialog title="查看题目"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <div class="card">
        <div class="card_head">
          <span>题目编号：{{form.question_id}}</span> 题目分数:<span style="color:#FF6147">{{form.question_score}}</span>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目内容：</span>
          </div>
          <div>
            <div class="topic_content"
                 v-html="$replaceAll(form.question_head_content)">
            </div>
            <div class="topic_content"
                 v-html="$replaceAll(form.question_content)"></div>
          </div>
          <div class="bottom_border"></div>

        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目答案：</span>
          </div>
          <div class="topic_content"
               style="color:#FF806C"
               v-html="$replaceAll(form.question_answer)">
          </div>
          <div class="bottom_border"></div>

        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目解释：</span>
          </div>
          <div style=""
               class="topic_content"
               v-html="$replaceAll(form.question_explain)">
          </div>
          <div class="bottom_border"></div>
        </div>
      </div>
      <div class="comment"
           v-if="form.category">
        得分:{{form.user_score}}
      </div>
      <div class="comment"
           v-if="form.category">
        评语:{{form.teacher_comment}}
      </div>
      <div class="comment">
        反馈内容:{{form.comment}}
      </div>
      <div class="btn_warp"
           v-if="$route.path == '/feedback/audit' && showOpertion">
        <div class="btn primary"
             @click="pass">
          通过
        </div>
        <div class="btn danger"
             @click="notPass">
          不通过
        </div>
      </div>

      <div class="btn_warp"
           v-if="$route.path == '/feedback/list' && form.status == 1 ">
        <div class="btn primary"
             @click="reject">
          驳回
        </div>
      </div>

    </el-dialog>
    <Reject ref="RejectRef" />
    <notPass ref="notPassRef" />
    <pass ref="passRef" />
  </div>
</template>

<script>
import notPass from './notPass.vue'
import pass from './pass.vue'
import Reject from './reject.vue'
export default {
  data () {
    return {
      form: [],
      id: '',
      dialogVisible: false,
      showOpertion: true
    }
  },
  components: {
    notPass,
    pass,
    Reject
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.showOpertion = true
      this.dialogVisible = false
    },
    /**
     * @annotation 关闭当前窗口并且刷新列表
     */
    getParentList () {
      this.$parent.search()
      this.handleClose()
    },
    referenceParent () {
      this.handleClose()
      this.$parent.search()
    },
    getInfo (item) {
      if (!item.category) {
        this.form = JSON.parse(JSON.stringify(item))
        this.dialogVisible = true
        return
      }
      this.$http({
        url: '/api/v1/error/question_approval',
        method: 'get',
        params: {
          question_error_id: item.question_error_id
        }
      }).then(res => {
        res.data.question_error_id = item.question_error_id
        res.data.user_id = item.user_id
        res.data.category = item.category
        res.data.status = item.status
        this.form = res.data
        this.dialogVisible = true
      })
    },
    notPass () {
      let form = {
        question_error_id: this.form.question_error_id,
        edu_user_id: this.form.user_id,
        comment: '',
        status: -1
      }
      this.$refs.notPassRef.form = form
      this.$refs.notPassRef.dialogVisible = true
    },
    pass () {
      let form = {
        question_error_id: this.form.question_error_id,
        edu_user_id: '',
        status: 1,
        type_id: this.form.category ? 2 : 0
      }

      this.$http({
        url: '/api/v1/error/has_user',
        method: 'get',
        params: {
          question_error_id: this.form.question_error_id
        }
      }).then(res => {
        this.$refs.passRef.form = form
        if (!this.form.category && res.data.has_user) {
          this.$http({
            url: '/api/v1/error/passed',
            method: 'post',
            data: form
          }).then(res => {
            this.$message.success('成功')
            this.getParentList()
          })
        } else {
          this.$refs.passRef.getTypeList()
          this.$refs.passRef.getTeacher(this.form.question_error_id)
          this.$refs.passRef.checkUser = res.data.has_user
          this.$refs.passRef.category = this.form.category
          this.$refs.passRef.dialogVisible = true
        }
      })
    },
    reject () {
      let form = {
        question_error_id: this.form.question_error_id,
        teacher_comment: '',
        counter_image: ''
      }
      this.$refs.RejectRef.form = form
      this.$refs.RejectRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  font-size: 16px;
  padding-bottom: 20px;
  .card_head {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    background: #eff8ff;
    height: 60px;
    line-height: 60px;
    text-indent: 1em;
    span {
      color: #666666;
      margin-right: 20px;
    }
  }
}
.el-card {
  padding: 0 15px 15px 15px;
}
.box-card {
  margin-top: 20px;
  .clearfix {
    margin-bottom: 20px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    font-size: 16px;
    font-weight: bold;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    word-wrap: break-word;
  }
}
.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}

.comment {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

::v-deep .el-dialog__footer {
  text-align: center;
}
.el-input {
  width: 100px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.btn_warp {
  display: flex;
  justify-content: center;
  .btn {
    padding: 5px 23px;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    border-radius: 15px;
    cursor: pointer;
  }
  .primary {
    background: #3f9eff;
  }
  .danger {
    background: #fe765f;
    margin-left: 15px;
  }
}
</style>