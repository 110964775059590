function getFileMultiple ({
  limit,
  fileType,
  isActive
}) {
  !limit && (limit = 2)
  return new Promise((resolve, reject) => {
    limit = 1024
    let
      fileEle = document.createElement('input'),
      event = new MouseEvent('click')
    fileEle.type = "file"
    // 是否选择文件夹  true选择
    fileEle.webkitdirectory = isActive
    fileEle.accept = fileType || ""
    fileEle.multiple = true
    fileEle.style.display = 'none'
    fileEle.addEventListener('change', () => {
      console.log('files', fileEle.files);
      // const files = fileEle.files[0]
      resolve(fileEle.files)
      document.body.removeChild(fileEle)
    })
    fileEle.dispatchEvent(event)
    document.body.appendChild(fileEle)
  })
}

export default getFileMultiple