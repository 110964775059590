<template>
  <div>
    <el-dialog title="反馈类型"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form label-width="80px">
        <el-row v-if="category != 0">
          <el-form-item label="类型">
            <el-radio-group v-model="form.type_id">
              <el-radio :label="item.error_type_id"
                        v-for="item in typeList"
                        :key="item.error_type_id">{{item.type_desc}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-row v-if="!checkUser">
          <el-form-item label="教师">
            <el-select v-model="form.edu_user_id"
                       style="width:100%"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in teacherList"
                         :key="item.edu_user_id"
                         :label="item.edu_user_name"
                         :value="item.edu_user_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      typeList: [],
      checkUser: false,
      teacherList: [],
      category: ''
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },

    save () {
      if (!this.checkUser && !this.form.edu_user_id) {
        this.$message.warning('请分派教师')
        return
      }
      this.$http({
        url: '/api/v1/error/passed',
        method: 'post',
        data: this.form
      }).then(res => {
        this.handleClose()
        this.$message.success('成功')
        this.$parent.getParentList()
      })
    },
    getTypeList () {
      this.$http({
        url: '/api/v1/error/error_type',
        method: 'get'
      }).then(res => {
        this.typeList = res.data.list
      })
    },
    getTeacher (question_error_id) {
      this.$http({
        url: '/api/v1/error/teachers',
        method: 'get',
        params: {
          question_error_id
        }
      }).then(res => {
        this.teacherList = res.data.list
      })
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.el-input {
  width: 100px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>